import React, { useState } from "react"
import { connect } from "react-redux"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import ResponsiveSpacing from "../components/responsive-spacing"
import Layout from "../components/layout"
import HalfHero from "../components/half-hero"
import SEO from "../components/seo"
import BottomColorNav from "../components/bottom-color-nav"
import Img from "gatsby-image/withIEPolyfill"

const MembersStyled = styled.div`
  max-width: 1220px;
  padding: 0;
  margin: 150px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 34px;

  @media (max-width: 1268px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 968px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 768px) {
    padding: 0 10px;
    margin: 50px auto;
    grid-template-columns: 1fr;
  }

  .opening {
    text-align: left;
    @media (min-width: 768px) {
      grid-column: 1 / span 2;
    }

    @media (max-width: 768px) {
      grid-column: 1 / span 1;
    }
  }

  .member {
    /* max-width: 260px; */
    text-align: center;

    @media (max-width: 768px) {
      /* max-width: 1000px; */
      /* width: 100vw; */
    }
  }

  h4 {
    font-size: 1.4em;
    line-height: 1.8em;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    margin-top: 14px;
    text-transform: uppercase;
    font-weight: bold;
    color: ${(props) => props.theme.Blue};
    @media (max-width: 768px) {
      /* max-width: 100%; */
    }
  }

  .static {
    display: block;
  }

  .hover {
    display: none;
  }

  @media (min-width: 769px) {
    .member:hover .static {
      display: none;
    }
    .member:hover .hover {
      display: block;
    }
  }

  @media (max-width: 768px) {
    .member.active .static {
      display: none;
    }
    .member.active .hover {
      display: block;
    }
  }

  h2 {
    font-family: "interstate-compressed";
    color: ${(p) => p.theme.DarkBlue};
    text-transform: uppercase;
    font-size: 6em;
    font-weight: 800;
    letter-spacing: 2px;
    line-height: 0.9em;
    margin: 0;
    letter-spacing: 1.2px;
  }

  h3 {
    text-transform: uppercase;
    color: ${(props) => props.theme.Blue};
    margin: 10px 0;
    font-size: 2em;
    font-family: "Montserrat";
    letter-spacing: 1.2px;
  }

  .img {
    /* width: 260px; */

    @media (max-width: 768px) {
      /* width: calc(100vw - 68px); */
    }
  }

  .blank {
    /* width: 260px;
    height: 260px; */
    background: ${(props) => props.theme.BGBlue};

    @media (max-width: 768px) {
      /* width: calc(100vw - 68px); */
    }
  }

  p {
    color: ${(p) => p.theme.DarkBlue};
  }
`

const Members = ({ fields }) => {
  const [active, setActive] = useState(-1)
  const { opening, members } = fields

  return (
    <MembersStyled>
      <div className="opening">
        <h2
          tabIndex="0"
          dangerouslySetInnerHTML={{
            __html: opening.headline,
          }}
        />
        <h3
          tabIndex="0"
          dangerouslySetInnerHTML={{
            __html: opening.sub_heading,
          }}
        />
        <p
          tabIndex="0"
          dangerouslySetInnerHTML={{
            __html: opening.statement,
          }}
        />
      </div>
      {members.map((m, i) => {
        return (
          <div
            className={`member ${active === i ? "active" : ""}`}
            key={i}
            onClick={(e) => {
              if (active === i) setActive(-1)
              else setActive(i)
            }}
          >
            <div className="static">
              {m.image ? (
                <Img
                  alt={m.name}
                  className="img"
                  imgStyle={{
                    zIndex: 1,
                  }}
                  fluid={m.image.localFile.childImageSharp.fluid}
                />
              ) : (
                <div className="blank" />
              )}
            </div>
            <div className="hover">
              {m.hover_image ? (
                <Img
                  className="img"
                  loading="eager"
                  alt={m.name}
                  imgStyle={{
                    zIndex: 1,
                  }}
                  fluid={m.hover_image.localFile.childImageSharp.fluid}
                />
              ) : (
                <div className="blank" />
              )}
            </div>
            <h4
              className="name"
              tabIndex="0"
              dangerouslySetInnerHTML={{
                __html: m.name,
              }}
            />
          </div>
        )
      })}
    </MembersStyled>
  )
}

const TheTeamStyled = styled.div``

const TheTeam = ({ fields }) => {
  return (
    <TheTeamStyled>
      <HalfHero fields={fields} />
      <ResponsiveSpacing>
        <Members fields={fields} />
      </ResponsiveSpacing>
    </TheTeamStyled>
  )
}

const ConnectedTheTeam = connect((state) => state)(TheTeam)

const TheTeamPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressPage(filter: { slug: { eq: "the-team-page" } }) {
        edges {
          node {
            acf {
              hero_area {
                headline
                headline_color
                wave_color
                background {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 2560, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              opening {
                headline
                sub_heading
                statement
              }
              search_title
              search_description
              share_headline
              share_comment
              share_image {
                source_url
              }
              members {
                name
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 360, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                hover_image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 360, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const fields = data.allWordpressPage.edges[0].node.acf
  const {
    search_title,
    share_headline,
    search_description,
    share_comment,
    share_image,
  } = fields

  return (
    <Layout>
      <SEO
        title={search_title}
        description={search_description}
        headline={share_headline}
        share_comment={share_comment}
        image={share_image}
      />
      <ConnectedTheTeam fields={fields} />
      <BottomColorNav />
    </Layout>
  )
}

export default TheTeamPage
